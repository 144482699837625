.etoll-sent-hidden {
    display: none;
}
.content-wrapper {
    &.etoll-sent,
    &.sentgeo-sent {
        li {
            // display: grid;
            // grid-template-columns:  1fr 1fr 1fr 1fr;
            .id {
                margin-right: 1rem;
                min-width: 7rem;
            }
            .sent_timestamp {
                margin-right: 1rem;
                min-width: 10rem;
            }
            .duration {
                margin-right: 1rem;
                min-width: 6rem;   
            }
            .record_count {
                margin-right: 1rem;
                min-width: 6rem;
            }
        }
    }
    .summary-container {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $color--primary;
        margin-top: 1rem;
        margin-bottom: 1rem;
        .group {
            margin-right: 1rem;
            min-width: 12rem;
            border-left: 1px solid $color--primary;
            padding: 0rem 0.5rem 0.5rem 0.5rem;
            &.last {
            }
            &.refresh {
                border-left: none;
                margin-right: 0;
                min-width: auto;
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
                padding-right: 0;
            }
        }
    }
}